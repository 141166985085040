import React, { useEffect } from "react";
import { Autocomplete, Box, Button, CircularProgress, Grid, Modal, Paper, Toolbar } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import CommonForm from "../../utils/components/commonForm";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";

const EditForms = () => {
  const { reportID, templateID} = useParams();
  const [inputFields, setInputFields]= React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalReportCount, setTotalReportCount] = React.useState(0);
  const [templateData, setTemplateData] = React.useState({});
  const [inputStates, setInputStates] = React.useState(() => {
    if (templateID == 13) {
      return {
        typeIdentificationNumber: "Sr No:-\nCapacity:-\nLocation:-\nMake:-",
        equipmentId: "Lifting machine",
        enclosureOfHoist: "NA",
        landingGates: "NA",
        interlocksOnLanding: "NA",
        gateFastings: "NA",
        platformFittings: "NA",
        overrunningDevices: "NA",
        suspensionRopes: "NA",
        safetyGear: "NA",
        breaks: "NA",
        spurGaring: "NA",
        electronicalEquipment: "NA",
        defectDescription: "Nil",
        dateOfExamination: `Previously tested by us on dt.`,
        dateOfAnnealing : "NA",
        renewalInformation : "Nil",
        mainatance: "Not Applicable",
        repairsrenewals: "Nil"
      };
    } else {
      return {
        distinctiveNumber: "Sr No:-\nCapacity:-\nLocation:-",
        thicknessOfWall: "Shell Thickness:-\nDish Thickness:-",
        whomCarriedOut: "Tested by us",
        examinationPurpose: "Unlagged",
        externalExamination : "Externally examined for pitting, corrosion etc & found O.K.",
        internalExamination : "Inaccessible",
        hydraulicTestExamination : "Not Due",
        ultrasonicTest : "Ultrasonically tested & Found O.K.\nShell Thickness:-\nDish Thickness:-",
        vessel : "O.K.",
        piping : "Checked & found O.K.",
        safetyValve: "Checked & found O.K.",
        stopValve : "Checked & found O.K.",
        reducingValve:"Not Applicable",
        additinalSafety:"Not Applicable",
        otherDevices:"Not Applicable",
        appliancesMaintaince:"Not Applicable",
        safeWorkingPressure: "Not Applicable",
        pressureGauges:"Checked & found O.K.",
        repairsIfAnyAndPeriod:"Nil",
        specifyReducedWorkingPressure:"Not Applicable",
        conditionSubject:"Condition is satisfactory to be operated in safe working pressure.",
        internalTestDate:"Inaccessible",
        internalExaminationDueDate:"Inaccessible"
      };
    }
  });

  const updateInputObject = (key, value) => {
    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [key]: value,
    }));
  };

  const submitReport = async () => {
  
    try {
      const formConfigJson = JSON.stringify(inputStates);


      if (formConfigJson) {
        const reportData =
          reportID < 1
            ? {
                templateId: templateID,
                templateValues: formConfigJson,
                //reportNo: reportDetails?.reportNo,
                qrCodeNo: `ASLE${templateData?.name?.includes("11") ? `FM11` :`FM13`}${totalReportCount + 1}`,
              }
            : {
                templateValues: formConfigJson,
                // reportNo: reportDetails?.reportNo,
                // qrCodeNo: reportDetails?.qrCodeNo,
              };

        const reportEndpoint =
          reportID < 1
            ? BASE_URL + "reportValues"
            : BASE_URL + `reportValues/${reportID}`;

        const reportResponse = await (reportID < 1
          ? axiosWithToken.post(reportEndpoint, reportData)
          : axiosWithToken.patch(reportEndpoint, reportData));

        toast(
          reportResponse.status === 200
            ? "Report created/updated successfully!"
            : "Failed to create/update Report."
        );
        if (reportResponse.status === 200) {
          setTimeout(refresh, 500);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong!");
    }

  };

  const fetchformReport = async (id) => {
    setLoading(true);
    try {
      const res = await axiosWithToken.get(`${BASE_URL}reportValues/${id}`);
      const data = res.data[0];
  
      if (!data || !data.templateValues) {
        console.log("Template data not found or invalid");
        return;
      }
  
      const dataObject = data.templateValues && JSON.parse(data.templateValues);

  const newDataObject = { ...dataObject };
  // delete newDataObject.reportDate;
  setInputStates(newDataObject);
    } catch (error) {
      console.error("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchTemplate = (id) => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `templates/${id}`)
        .then((res) => {
          let data = res.data[0];
          setTemplateData(data)
          axios.get(data?.url).then((respose) => {
            setInputFields(respose.data);
          });

        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  const fetchAddress = (id) => {
    try {
      let SQLQuery={"query":`SELECT a.address, c.occupierName FROM addresses a left JOIN clients c ON SUBSTRING_INDEX(c.address, ',', 1) = a.id WHERE c.id = ${id}`}
      setLoading(true);
      axiosWithToken
        .post(BASE_URL + `dynamic`,SQLQuery)
        .then((res) => {
          let data = res.data[0];
          setInputStates(prevState => ({
            ...prevState,
            addressFactory: data?.address,
            Occupier: data?.occupierName  
          }));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  var refresh = () => {
    window.location.reload(false);
  };

  
  const getTotalCountOfReports = () => {


    let SQLQuery={
      "query":`SELECT COUNT(*) AS reportValues_count FROM reportValues`
    }

    axiosWithToken
      .post(BASE_URL + `dynamic`,SQLQuery)
      .then((res) => {
        setTotalReportCount(res.data?.[0]?.reportValues_count)
      })
      .catch((err) => {
        toast.error("Something Went Wrong!")
      });
  };

  useEffect(() => {
    if (reportID == 0 && templateID == 13) {
      setInputStates(prevState => ({
        ...prevState,
        numberOfCertification: [
          prevState.reportNumber,
          prevState.inspectionDate && moment(prevState.inspectionDate).format("DD/MM/YYYY")
        ].filter(Boolean).join('\n').trim()
      }));
    }
  }, [inputStates?.reportNumber, inputStates?.inspectionDate]);

  useEffect(()=>{
    getTotalCountOfReports()
  },[])

useEffect(()=>{
 if(inputStates?.factoryId) fetchAddress(inputStates?.factoryId)
},[inputStates?.factoryId])

  useEffect(() => {
    if (templateID > 0) {
      fetchTemplate(templateID);
    }
    if (reportID > 0) {
      fetchformReport(reportID);
    } 
  }, [reportID, templateID]);

  return (
    <>
      <Paper sx={{ mt: 2, p: 4 }}>
        <CommonForm
          inputStates={inputStates}
          updateInputObject={updateInputObject}
          inputFields={inputFields}
          isTemplateForm={true}
        />

        <Toolbar
          style={{
            padding: "0px",
            overflow: "auto",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              submitReport();
            }}
          >
            {reportID > 0 ? "Update" : "Save"}
          </Button>
        </Toolbar>
        <Modal
        open={loading}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100vh",
            bgcolor: "background.paper",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="secondary" size={200} />
        </Box>
      </Modal>
      </Paper>
    </>
  );
};

export default EditForms;
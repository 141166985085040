import React, { useEffect, useRef } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from "react-toastify";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";
import logoImage from "../image/AasthLogo.png";


var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "name",
    label: "Form Type",
    align: "left",
    minWidth: 40,
  },
  {
    id: "reportNumber",
    label: "Report Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "tagNumber",
    label: "Tag Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "qrCodeNo",
    label: "QR Code Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "client_name",
    label: "Factory name",
    align: "left",
    minWidth: 40,
  },
  ,
  {
    id: "reportDate",
    label: "Report Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificateValidity",
    label: "Renewal Date",
    align: "left",
    minWidth: 40,
  },
];

const qrModalStyle = {
  width: "350px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const FormReportsList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [templateList, setTemplateList] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(0);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const handleClose = () => setQrModalOpen(false);
  const [rowId, setRowId] = React.useState(0);
  const [templateId, setTemplateId] = React.useState(0);
  const printComponentRef = useRef();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [SelectedStickerData, setSelectedStickerData] = React.useState({});


  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";
 
    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Form Type": "templates.name",
        "Report Number": "REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportNumber'), '\"', '')",
        "Tag Number": "REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.tagNumber'), '\"', '')",
        "QR Code Number": "qrCodeNo",
        "Factory Name": "clients.name",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (_userType == 3)
      whereConditions +=
        whereConditions.length > 0
          ? `and JSON_EXTRACT(reportValues.templateValues, '$.factoryId') = ${_clientId}`
          : `where JSON_EXTRACT(reportValues.templateValues, '$.factoryId') = ${_clientId}`;
   
    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT reportValues.*,REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportDate'), '\"', '') AS reportDate, REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.certificateValidity'), '\"', '') AS certificateValidity, REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportNumber'), '\"', '') AS reportNumber, REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.tagNumber'), '\"', '') AS tagNumber, templates.name, clients.name AS client_name, addresses.address AS client_address, clients.emailId AS client_email FROM reportValues LEFT JOIN templates ON reportValues.templateId = templates.id LEFT JOIN clients ON JSON_EXTRACT(reportValues.templateValues, '$.factoryId') = clients.id LEFT JOIN addresses ON addresses.id = SUBSTRING_INDEX(clients.address, ',', 1)
      ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM reportValues LEFT JOIN templates ON reportValues.templateId = templates.id ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }

  const search = () => {
    fetchFinalData();
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `reportValues/${id}`)
      .then((res) => {
        toast("Report is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getAllTemplate = () => {
    axiosWithToken
      .get(BASE_URL + `templates`)
      .then((res) => {
        setTemplateList(res.data);
      })
      .catch((err) => {
        console.log(" error: ", err);
      });
  };

  const copyReport = async (id) => {
    const numberOfCopies = parseInt(window.prompt("Enter the number of copies you want to create for the selected report:", "1"), 10);
    if (isNaN(numberOfCopies) || numberOfCopies <= 0) {
      return;
    }
  
    try {
      const TotalReportCount = await getTotalReportCount();
      const reportData = await getReportDataById(id);
      // Loop to create the specified number of copies
      for (let i = 0; i < numberOfCopies; i++) {
      const newQrCode = generateQrCode(reportData.templateId, TotalReportCount + i + 1);
      
    const newReportData = {
        ...reportData,
        qrCodeNo: newQrCode,
      };
      delete newReportData.id;
  
      await axiosWithToken.post(BASE_URL + "reportValues", newReportData);
      }  
      setTimeout(refresh, 500);
    } catch (error) {
      console.error("Error copying report:", error);
    }
  };
  
  const copyReports = async () => {
    if (!window.confirm("Do you want to copy and create new Reports using the selected Reports?")) {
      return;
    }
  
    try {
      const SQLQuery = {
        query: `SELECT templateId, templateValues FROM reportValues WHERE id IN (${selectedRows?.join(",")})`,
      };
  
      const reportData = await axiosWithToken
        .post(BASE_URL + `dynamic`, SQLQuery)
        .then((res) => res.data || []);
  
      const TotalReportCount = await getTotalReportCount();
  
      const newReportData = reportData.map((item, index) => {
        const newQrCode = generateQrCode(item.templateId, TotalReportCount + index + 1);
        return {
          ...item,
          qrCodeNo: newQrCode,
        };
      });
  
      newReportData.forEach((item) => delete item.id);
  
      await axiosWithToken.post(BASE_URL + "reportValues/bulk", newReportData);
      toast("Reports copied successfully!");
      setTimeout(refresh, 500);
    } catch (error) {
      console.error("Error copying reports:", error);
    }
  };
  
  // Helper functions
  const getTotalReportCount = async () => {
    return await axiosWithToken
      .post(BASE_URL + `dynamic`, {
        query: `SELECT COUNT(*) AS reportValues_count FROM reportValues`,
      })
      .then((res) => res?.data[0]?.reportValues_count);
  };
  
  const getReportDataById = async (id) => {
    return await axiosWithToken
      .get(BASE_URL + `reportValues/${id}`)
      .then((res) => res.data[0] || {});
  };
  
  const generateQrCode = (templateId, count) => {
    return `ASLE${templateId == 13 ? `FM11` : `FM13`}${count}`;
  };
  
  const refresh = () => {
    window.location.reload(false);
  };
  

  const deleteReports = () => {

    const confirmed = window.confirm(
      "Do want to Delete selected Reports?"
    );
    if (!confirmed) {
      return;
    }

    let SQLQuery={
      "query":`DELETE FROM reportValues WHERE id IN (${selectedRows?.join(",")})`
    }

    axiosWithToken
      .post(BASE_URL + `dynamic`,SQLQuery)
      .then((res) => {
        toast("Reports deleted Successfully !")
        refresh()
      })
      .catch((err) => {
        toast.error("Something Went Wrong!")
      });
  };

const getStickerData = ()=>{
  let newData= data?.filter((ele)=>ele.id === rowId)?.[0]
  setSelectedStickerData(newData)
}

useEffect(() => {
 if(data.length > 0) getStickerData()
}, [data,rowId]);

  useEffect(() => {
    getAllTemplate();
    getTotalRows();
  }, []);

  useEffect(() => {
    fetchFinalData();
  }, [rowsPerPage, page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit Report",
      link: true,
      linkTo: (row) => `/editForms/${row.id}/${row.templateId}`,
      icon: <PreviewIcon />,
    },
    {
      id: "copy",
      tooltip: "Copy Report ",
      icon: <ContentCopyIcon />,
      handler: (row) => copyReport(row.id),
    },
    // {
    //   id: "qrCode",
    //   tooltip: "Make a QR Code",
    //   icon: <QrCode2Icon color="primary" />,
    //   handler: (row) => {
    //     setRowId(row.id);
    //     setTemplateId(row.templateId);
    //     setQrModalOpen(true);
    //   },
    // },
    // {
    //   id: "sticker",
    //   tooltip: "Print Sticker",
    //   link: true,
    //   linkTo: (row) => `/sticker/${row.id}`,
    //   icon: <QrCode2Icon />,
    // },
    
    {
      id: "print",
      tooltip: "Print Report",
      link: true,
      linkTo: (row) => `/printForm/${row.id}/${row.templateId}`,
      icon: <PrintIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete Report",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (row) =>
        window.confirm("Are you sure you want to delete this?") &&
        handleDelete(row?.id),
    },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2, p: 2 }}>
{
  _userType == "1" && <Toolbar>
  <Grid container spacing={2}>
    <Grid item xs={12} sm={4}>
      <FormControl fullWidth variant="outlined" size="small" >
        <InputLabel id="field-type-label">Form Types</InputLabel>
        <Select
          labelId="field-type-label"
          onChange={(e) => {
            setSelectedTemplate(e.target.value);
          }}
          label="Field Type"
          size="small"
        >
          {templateList.map((rec) => {
            return <MenuItem value={rec.id}>{rec.name}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Grid>

    <Grid item xs={12} sm={2}>
      <Button
        variant="contained"
        size="small"
        component={Link}
        to={`/editForms/0/${selectedTemplate}`}
      >
        <b>Create new</b>
      </Button>
    </Grid>
  </Grid>
</Toolbar>
}
        
        <br />
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            className="mb-2"
            style={{
              display: "flex",
              float: "left",
              justifyContent: "flex-end",
            }}
          >
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
                marginLeft: "10px",
              }}
              size="small"
              id="combo-box-demo"
              options={
              [
                    { key: "templates.name", label: "Form Type" },
                    { key: "REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.reportNumber'), '\"', '')", label: "Report Number" },
                    { key: "REPLACE(JSON_EXTRACT(reportValues.templateValues, '$.tagNumber'), '\"', '')", label: "Tag Number" },
                    { key: "clients.name", label: "Factory Name" },
                    { key: "qrCodeNo", label: "QR Code Number" },
                  ]
                 
              }
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                search();
              }}
            >
              <SearchIcon />
            </Button>
{selectedRows?.length > 0 &&
<>
<Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
              startIcon={<LocalPrintshopIcon />}
              component={Link}
               to={`/multiplePrintForm?ids=${selectedRows?.join(",")}`}
            >
           Reports
            </Button>

            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
              startIcon={<QrCode2Icon />}
              component={Link}
               to={`/multipleStickers?ids=${selectedRows?.join(",")}`}
            >
           Stickers
            </Button>

            <Button
              variant="contained"
              size="small"
              sx={{ m: 0  }}
              style={{ marginLeft: "10px"}}
              startIcon={<ContentCopyIcon />}
             onClick={copyReports}
            >
           Reports
            </Button>

            <Button
              variant="contained"
              size="small"
              sx={{ m: 0  }}
              style={{ marginLeft: "10px", backgroundColor: "#d90404", color: "white" }}
              startIcon={<DeleteIcon />}
             onClick={deleteReports}
            >
           Reports
            </Button>
</>
}
           
          </div>
        </div>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
          multipleSelector={true}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </TableContainer>
      <Modal
        ref={printComponentRef}
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box sx={qrModalStyle}>
            <div id="componentToPrint">
              {/* <img src={"https://i.pinimg.com/564x/f1/bd/b8/f1bdb8078027d8d81829e1539b4fcaf7.jpg"} alt="img" height={"350px"} /> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "70px",
                  backgroundColor: "#ac0b4f",
                  padding: "10px",
                }}
              >
                <img
                  src={logoImage}
                  alt=""
                  height={"46px"}
                  style={{ borderRadius: "50%" }}
                />
                <h5 style={{ fontWeight: "bold", color: "white" }}>
                Aastha Safe Link Engineering
                </h5>
              </div>
              <div>
                <Paper
                  elevation={0}
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f3dfe7",
                    padding: "10px",
                  }}
                >
                  <h6 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    Just Scan, To Know Equipment Details
                  </h6>
                  <QRCode
                    value={`${`http://localhost:3001`}/#/printForm/${rowId}/${templateId}/print`}
                    align="center"
                    size={200}
                  />
                     <h6 style={{ fontWeight: "bold", marginBottom: "10px", marginTop:"10px", fontSize:"25px" }}>
                    {SelectedStickerData?.qrCodeNo}
                  </h6>
                </Paper>
              </div>
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "#ac0b4f",
                  padding: "10px",
                }}
              >
                <h5 style={{ fontWeight: "bold", color: "white", marginBottom:"10px" }}>
                  CALL: +91 99209-04473
                </h5>
                <h6 style={{ fontWeight: "bold" }}>
                  504, Umiya Kiran,Plot NO:71
                  <br />
                  Sector:21 Kamothe,
                  <br />
                  Navi Mumbai:410209, Maharashtra
                </h6>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default FormReportsList;
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import FormPrint from "./formPrint";
import { Button, Toolbar } from "@mui/material";
import html2pdf from "html2pdf.js";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";

const MultiplePrintForm = () => {
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const [FormData, setFormData] = useState([]);
  const elementRef = useRef(null);
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);

  const fetchFormData = () => {
    try {
      let SQLQuery = {
        query: `SELECT id, templateId FROM reportValues WHERE id IN (${ids.join(
          ","
        )}) ORDER BY id DESC`,
      };

      axiosWithToken.post(BASE_URL + `dynamic`, SQLQuery).then((res) => {
        let data = res.data;
        setFormData(data);
      });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = elementRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [5, 5, 5, 5],
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");
      
       // border add for contnet
       const borderWidth = 0.6;
       const leftMargin = 6;
       const borderX = leftMargin;
       const borderY = 5;
       const rightMargin = 595 - leftMargin * 0.95;
       pdf.setLineWidth(borderWidth);
       pdf.rect(borderX, borderY, rightMargin - leftMargin, 830);

      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `form.pdf`;
      a.click();

      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  useEffect(() => {
    if (ids?.length > 0) fetchFormData();
  }, [ids]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idsParam = searchParams.get("ids");
    if (idsParam) {
      const idsArray = idsParam.split(",").map((id) => parseInt(id));
      setIds(idsArray);
    }
  }, [location.search]);
  return (
    <>
      <Toolbar
            style={{
              padding: "0px",
              overflow: "auto",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ marginRight: "20px" }}
              onClick={generatePDF}
            >
              Report Print
            </Button>
          </Toolbar>
    <div
     id="formToPrint"
     ref={elementRef}
    >
      {FormData?.sort((a, b) => a.id - b.id)?.map((ele) => {
        return (
          <div style={{ pageBreakAfter: 'always' }} >
            <FormPrint 
            selectedFormID ={ele?.id}
            selectedTemplateID={ele?.templateId}
            />
            
          </div>
        );
      })}
    </div>
  
    {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Pdf"} />
      )}
    </>
  );
};

export default MultiplePrintForm;

import React, { useEffect, useRef } from "react";
import stampImage from "../image/BfiStamp.png";
import { Box, Button, Paper, Toolbar } from "@mui/material";
import { useParams } from "react-router";
import moment from "moment";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL , DOMAIN } from "../../global";
import QRCode from "react-qr-code";
import logoImage from "../image/AasthLogo.png";

const qrModalStyle = {
  width: "250px",
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  marginBottom:"50px",
  // transform: "translate(-50%, -50%)",
  // width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const Sticker = ({ selectedFormID }) => {
  const elementRef = useRef(null);
  const { reportID, templateID } = useParams();
  const [newReportID, setNewReportID] = React.useState(
    reportID || selectedFormID
  );
  // const [newTemplateID, setNewTemplateID] = React.useState(templateID || selectedTemplateID);
  const [inputStates, setInputStates] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [data, setFinalData] = React.useState({});

  const handlePrint = () => {
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  };
  const fetchformReport = async (id) => {
    setLoading(true);
    try {
      const res = await axiosWithToken.get(`${BASE_URL}reportValues/${id}`);
      const data = res.data[0];
      setFinalData(data)
      if (!data || !data.templateValues) {
        console.log("Template data not found or invalid");
        return;
      }

      const dataObject = data.templateValues && JSON.parse(data.templateValues);

      const newDataObject = { ...dataObject };
      // delete newDataObject.reportDate;
      setInputStates(newDataObject);
    } catch (error) {
      console.error("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (newReportID > 0) {
      fetchformReport(newReportID);
    }
  }, [newReportID]);

  return (
    <>
      {reportID && (
        <Toolbar
          style={{
            padding: "0px",
            overflow: "auto",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ marginRight: "20px" }}
            onClick={handlePrint}
          >
            Report Print
          </Button>
        </Toolbar>
      )}

      <div id="formToPrint" ref={elementRef} >
      <div style={{pageBreakInside:"avoid"}}>
          <Box sx={qrModalStyle}>
            <div id="componentToPrint">
              {/* <img src={"https://i.pinimg.com/564x/f1/bd/b8/f1bdb8078027d8d81829e1539b4fcaf7.jpg"} alt="img" height={"350px"} /> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "20px",
                  backgroundColor: "#ac0b4f",
                  padding: "10px",
                }}
              >
                <img
                  src={logoImage}
                  alt=""
                  height={"46px"}
                  style={{ borderRadius: "50%" }}
                />
                <h6 style={{ fontWeight: "bold", color: "white", fontSize:"18px" }}>
                Aastha Safe Link Engineering
                </h6>
              </div>
              <div>
                <Paper
                  elevation={0}
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f3dfe7",
                    padding: "10px",
                  }}
                >
                  <h6 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    Just Scan, To Know Equipment Details
                  </h6>
                  <QRCode
                    value={DOMAIN+`/#/printForm/${newReportID}/${data?.templateId}/print`}
                    align="center"
                    size={60}
                  />
                     <h6 style={{ fontWeight: "bold", marginBottom: "10px", marginTop:"10px", fontSize:"22px" }}>
                    {data?.qrCodeNo}
                  </h6>
                </Paper>
              </div>
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "#ac0b4f",
                  padding: "5px",
                }}
              >
                <h6 style={{ fontWeight: "bold", color: "white", fontSize:"18px" }}>
                  CALL: +91 99209-04473
                </h6>
                {/* <h6 style={{ fontWeight: "bold" }}>
                  504, Umiya Kiran,Plot NO:71
                  <br />
                  Sector:21 Kamothe,
                  <br />
                  Navi Mumbai:410209, Maharashtra
                </h6> */}
              </div>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Sticker;

import React, { useEffect } from "react";
import { BASE_URL } from "./../../global";
import Tables from "../../utils/components/tables";
import PrintIcon from "@mui/icons-material/Print";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { checkListNames } from "./constant";

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 20 },
  { id: "customerName", label: "Customer Name", minWidth: 40 },
  { id: "inspectedBy", label: "Inspected By", minWidth: 40 },
];

const CheckList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const [data, setFinalData] = React.useState([]);
  const [selectedChecklist, setSelectedChecklist] = React.useState(1);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Customer Name": "REPLACE(JSON_EXTRACT(configuration, '$.customerName'), '\"', '')",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT * , REPLACE(JSON_EXTRACT(configuration, '$.customerName'), '\"', '') AS customerName, REPLACE(JSON_EXTRACT(configuration, '$.inspectedBy'), '\"', '') AS inspectedBy from checkList  ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM checkList  ${whereConditions}  order by id DESC ${pagination_settings}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }

  const search = () => {
    fetchFinalData();
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `checkList/${id}`)
      .then((res) => {
        toast("checkList is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    fetchFinalData();
  }, [rowsPerPage, page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit User",
      link: true,
      linkTo: (row) => `/editcheckList/${checkListNames.findIndex(name => name === row.name)+1}/${row.id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "print",
      tooltip: "Print CheckList",
      link: true,
      linkTo: (row) => `/editcheckList/${checkListNames.findIndex(name => name === row.name)+1}/${row.id}/print`,
      icon: <PrintIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete User",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (row) =>
        window.confirm("Are you sure you want to delete this?") &&
        handleDelete(row?.id),
    },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="field-type-label">CheckList Types</InputLabel>
                <Select
                  labelId="field-type-label"
                  onChange={(e) => {
                    setSelectedChecklist(e.target.value);
                  }}
                  label="Field Type"
                  size="small"
                >
                  <MenuItem value={1}>
                    ROPE SUSPENDEDPLATFORM / CRADLES
                  </MenuItem>
                  <MenuItem value={2}>Passenger / Material Hoist</MenuItem>
                  <MenuItem value={3}>Tower Crane / Luffing Crane</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                size="small"
                component={Link}
                to={`/editcheckList/${selectedChecklist}/0`}
              >
                <b>Create new</b>
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        <br />
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            className="mb-2"
            style={{
              display: "flex",
              float: "left",
              justifyContent: "flex-end",
            }}
          >
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
                marginLeft: "10px",
              }}
              size="small"
              id="combo-box-demo"
              options={[{ key: "REPLACE(JSON_EXTRACT(configuration, '$.customerName'), '\"', '')", label: "Customer Name" }]}
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                search();
              }}
            >
              <SearchIcon />
            </Button>
          </div>
        </div>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
};

export default CheckList;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Toolbar,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { CheckList1, CheckList2, CheckList3, checkListNames } from "./constant";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import axios from "axios";

const cellStyles = {
  labelColumn: {
    padding: "5px",
    fontWeight: "bold",
    textAlign: "left",
    border: "none",
  },
  textfield: {
    width: "100%",
  },
  labelColumnborder: {
    padding: "5px",
    fontWeight: "bold",
    textAlign: "left",
    width: "25%",
  },
};

const EditCheckList = () => {
  const { checkListId, checkListtypeId } = useParams();
  const [formData, setFormData] = useState({});
  const [checkListType, setcheckListType] = useState([]);
  console.log({ checkListtypeId, checkListId });
  const handleInputChange = (key, value) => {
    if (key === "result") {
      setFormData((prevData) => ({
        ...prevData,
        result: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    }
  };

  let refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    let payload = {
      name:
        checkListtypeId == 1
          ? checkListNames[0]
          : checkListtypeId == 2
          ? checkListNames[1]
          : checkListNames[2],
      configuration: JSON.stringify(formData),
    };
    console.log(payload);
    if (checkListId > 0) {
      axios
        .patch(url + `checkList/${checkListId}`, payload)
        .then((res) => {
          toast("Checklist Update Successfully!");
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(url + "checkList", payload)
        .then((res) => {
          toast("Checklist Submit Successfully!");
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getChecklist = (event) => {
    let url = BASE_URL;
    axios
      .get(url + `checkList/${checkListId}`)
      .then((res) => {
        console.log(res.data);
        const checklistData = res.data[0];
        const parsedConfiguration = JSON.parse(checklistData.configuration);
        setFormData(parsedConfiguration);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (checkListId != 0) getChecklist();
  }, [checkListId]);

  useEffect(() => {
    if (checkListtypeId == 1) {
      setcheckListType(CheckList1);
    } else if (checkListtypeId == 2) {
      setcheckListType(CheckList2);
    } else if (checkListtypeId == 3) {
      setcheckListType(CheckList3);
    }
  }, [checkListtypeId]);

  console.log({ formData });
  return (
    <Box sx={{ width: "95%", margin: "2rem auto" }}>
      <h5>
        {checkListtypeId == 1 || checkListtypeId == 2
          ? `INSPECTION AND SAFETY AUDIT CHECKLIST FOR `
          : `INSPECTION CHECKLIST FOR `}

        <u style={{ textTransform: "uppercase" }}>
          {checkListtypeId == 1
            ? checkListNames[0]
            : checkListtypeId == 2
            ? checkListNames[1]
            : checkListNames[2]}
        </u>
      </h5>
      <div>
        <table style={{ width: "100%" }}>
          <tr>
            <td style={cellStyles.labelColumn}>
              <b>Date of Inspection:</b>
            </td>
            <td style={cellStyles.labelColumn}>
              <TextField
                variant="standard"
                style={cellStyles.textfield}
                value={formData.dateOfInspection || ""}
                onChange={(e) =>
                  handleInputChange("dateOfInspection", e.target.value)
                }
              />
            </td>
            <td style={cellStyles.labelColumn}>
              <b>Inspected By:</b>
            </td>
            <td style={cellStyles.labelColumn}>
              <TextField
                variant="standard"
                style={cellStyles.textfield}
                value={formData.inspectedBy || ""}
                onChange={(e) =>
                  handleInputChange("inspectedBy", e.target.value)
                }
              />
            </td>
          </tr>
          <tr>
            <td style={cellStyles.labelColumn}>
              <b>Customer Name:</b>
            </td>
            <td colSpan={3} style={cellStyles.labelColumn}>
              <TextField
                variant="standard"
                style={cellStyles.textfield}
                value={formData.customerName || ""}
                onChange={(e) =>
                  handleInputChange("customerName", e.target.value)
                }
              />
            </td>
          </tr>
          <tr>
            <td style={cellStyles.labelColumn}>
              <b>Site Address:</b>
            </td>
            <td colSpan={3} style={cellStyles.labelColumn}>
              <TextField
                variant="standard"
                style={cellStyles.textfield}
                value={formData.sitaddress || ""}
                onChange={(e) =>
                  handleInputChange("sitaddress", e.target.value)
                }
              />
            </td>
          </tr>
        </table>
      </div>
      <br />
      <div>
        <table style={{ width: "100%" }}>
          {checkListType.map(
            (item, index) =>
              index % 2 === 0 && (
                <tr key={index}>
                  <td style={cellStyles.labelColumnborder}>
                    <b>{item.label}</b>
                  </td>
                  <td style={cellStyles.labelColumnborder}>
                    {item.type === "Textfield" && (
                      <TextField
                        variant="standard"
                        style={cellStyles.textfield}
                        value={formData[item.key]}
                        onChange={(e) =>
                          handleInputChange(item.key, e.target.value)
                        }
                      />
                    )}
                    {item.type === "Checkbox" && (
                      <div>
                        {item.options.map((option, optionIndex) => (
                          <FormControlLabel
                            key={optionIndex}
                            control={
                              <Checkbox
                                checked={formData[item.key] === option}
                                onChange={(e) =>
                                  handleInputChange(item.key, option)
                                }
                              />
                            }
                            label={option}
                            style={{ paddingLeft: 0 }}
                          />
                        ))}
                      </div>
                    )}
                  </td>
                  <td style={cellStyles.labelColumnborder}>
                    <b>{checkListType[index + 1]?.label}</b>
                  </td>
                  <td style={cellStyles.labelColumnborder}>
                    {checkListType[index + 1]?.type === "Textfield" && (
                      <TextField
                        variant="standard"
                        style={cellStyles.textfield}
                        value={formData[checkListType[index + 1]?.key]}
                        onChange={(e) =>
                          handleInputChange(
                            checkListType[index + 1]?.key,
                            e.target.value
                          )
                        }
                      />
                    )}
                    {checkListType[index + 1]?.type === "Checkbox" && (
                      <div>
                        {checkListType[index + 1]?.options.map(
                          (option, optionIndex) => (
                            <FormControlLabel
                              key={optionIndex}
                              control={
                                <Checkbox
                                  checked={
                                    formData[checkListType[index + 1]?.key] ===
                                    option
                                  }
                                  onChange={(e) =>
                                  handleInputChange(
                                      checkListType[index + 1]?.key,
                                      option
                                    )
                                  }
                                />
                              }
                              label={option}
                              style={{ paddingLeft: 0 }}
                            />
                          )
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              )
          )}
          <tr>
            <th colSpan={4} style={cellStyles.labelColumnborder}>
              <b>Any Other Important information not covered in check list:</b>
              <br />
              <TextField
                variant="standard"
                style={cellStyles.textfield}
                value={formData.otherInfo}
                onChange={(e) => handleInputChange("otherInfo", e.target.value)}
              />
            </th>
          </tr>
          {checkListtypeId == 3 &&
          <tr>
            <th colSpan={4} style={cellStyles.labelColumn}>
              <b>Type of Test:</b>
              {["Visula", "Load Test"].map(
                (option, optionIndex) => (
                  <FormControlLabel
                    key={optionIndex}
                    control={<Checkbox />}
                    label={option}
                    style={{ paddingLeft: 0 }}
                    checked={formData.typetest === option}
                    onChange={(e) => handleInputChange("typetest", option)}
                  />
                )
              )}
            </th>
          </tr>
          }
          <tr>
            <th colSpan={4} style={cellStyles.labelColumn}>
              <b>Result:</b>
              {["Fit for use", "Need rapirs", "Unfit for use"].map(
                (option, optionIndex) => (
                  <FormControlLabel
                    key={optionIndex}
                    control={<Checkbox />}
                    label={option}
                    style={{ paddingLeft: 0 }}
                    checked={formData.result === option}
                    onChange={(e) => handleInputChange("result", option)}
                  />
                )
              )}
            </th>
          </tr>
        </table>
       
      </div>
      <br />
      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ marginRight: "20px" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Toolbar>
    </Box>
  );
};
export default EditCheckList;

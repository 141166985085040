export const checkListNames = [
  "ROPE SUSPENDEDPLATFORM / CRADLES",
  "Passenger / Material Hoist",
  "Tower Crane / Luffing Crane",
];

export const CheckList1 = [
  {
    label: "(1)Identification/Description:",
    key: "(1)",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.6 Wire Rope Condition",
    key: "3.6",
    value: "",
    type: "Checkbox",
    options: ["ok", "Not-ok", "NA"],
  },
  {
    label: "1.1 Name of Manufacturing",
    key: "1.1",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.7 Hammer Weight Fitting",
    key: "3.7",
    value: "",
    type: "Checkbox",
    options: ["ok", "Not-ok", "NA"],
  },
  {
    label: "1.2 SRP Sr .No.",
    key: "1.2",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.8 Excess Rope Properly Stored",
    key: "3.8",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.3 SRP type(SRP/Cradle)",
    key: "1.3",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.9 Motor Mounting & Frame Tightness",
    key: "3.9",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.4 Model No:",
    key: "1.4",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.10 Roof top Devices Position",
    key: "3.10",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.5 Location/ Tower Name:",
    key: "1.5",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.11 Roof top Devices Tightness",
    key: "3.11",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.6 Motor Capacity",
    key: "1.6",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.12 Reinforce wire rope Tightness",
    key: "3.12",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.7 Present Height",
    key: "1.7",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.13 Counter Weight",
    key: "3.13",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.8 Hoist Motor Sr. No:(L)",
    key: "1.8",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.14 Counter Weight locking",
    key: "3.14",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.9 Safety Lock Sr. No.(L)",
    key: "1.9",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.15 GearBox Covers",
    key: "3.15",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.10 Hoist Motor Sr. No(R)",
    key: "1.10",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.16 Wall Guide Roller",
    key: "3.16",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.11 Safety Lock Sr. No(R)",
    key: "1.11",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.17 Caster wheel",
    key: "3.17",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.12 Wire Rope Dia :(Working rope)",
    key: "1.12",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.18 Main Power Supply Cable",
    key: "3.18",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.13 Wirerope Dia :(Safety rope)",
    key: "1.13",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.19 Cable Connectors",
    key: "3.19",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.14 Travelling Speed(m/min)",
    key: "3.14",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.20 Main Switch Functioning",
    key: "3.20",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "1.15 Platform Size:(L x B)",
    key: "1..15",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.21 Emergency Switch",
    key: "3.21",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "(2) Manufacturing Details",
    key: "(2)",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.22 Socket & Plug",
    key: "3.22",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "2.1 Manufacturing Date/date of 1st use at site",
    key: "2.1",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.23 Upper stopper for limit",
    key: "3.23",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "(3) Inspection Check Points",
    key: "(3)",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.24 LimitSwitch Functioning(L)",
    key: "3.24",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "3.1 Frames jointing Bolts & Pin",
    key: "3.1",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.25 LimitSwitch Functioning(R)",
    key: "3.25",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "3.2 Oil Leakage",
    key: "3.2",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.26 SafetyDeviceFunctioning(L)",
    key: "3.26",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "3.3 Self Braking of Motors",
    key: "3.3",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.27 Safety Device Functioning(R)",
    key: "3.27",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "3.4Motor Synchronizing System",
    key: "3.4",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.28 Safety Rope Provided",
    key: "3.28",
    value: "",
    options: ["ok", "Not-ok", "NA"],
    type: "Checkbox",
  },
  {
    label: "3.5 Motor with Gear Box Function",
    key: "3.5",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.29 Load Test (kg)",
    key: "3.29",
    value: "",
    type: "Textfield",
  },
];

export const CheckList2 = [
  {
    label: "(1) Identification/Description",
    key: "(1)",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.13 Any Oil Leakage from Gearbox",
    key: "3.13",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.1 Name of Manufacturer",
    key: "1.1",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.14 Cable Trolley Functioning",
    key: "3.14",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.2 Hoist Sr. No",
    key: "1.2",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.15 Motor with Gear box function",
    key: "3.15",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.3 Hoist Model No",
    key: "1.3",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.16 Rack Condition",
    key: "3.16",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.4 Safety Lock/device Sr.No. Date of Expiry",
    key: "1.4",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.17 Pinion Condition",
    key: "3.17",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.5 Motor Capacity (KW)",
    key: "1.5",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.18 Cable Arm Fitting",
    key: "3.18",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.6 Maximum Load Capacity",
    key: "1.6",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.19 Motor Mounting & Fittings",
    key: "3.19",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.7 Cage Size",
    key: "1.7",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.20 Lubrication of masts & Racks",
    key: "3.20",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.8 Installed at: Ground/ Podium",
    key: "1.8",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.21 Wall Ties",
    key: "3.21",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.9 Hoist Present working Height:",
    key: "1.9",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.22 Frames Jointing bolts & pin",
    key: "3.22",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.10 Travel Speed(m/min)",
    key: "1.10",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.23 Cable connection",
    key: "3.23",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.11 Location",
    key: "1.11",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.24 Main switch",
    key: "3.24",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "1.12 SWL/WLL Approved:",
    key: "1.12",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.25 Emergency switch",
    key: "3.25",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "[2] Manufacturing Date/date of 1st use at site",
    key: "2",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.26 Final Limit Switch",
    key: "3.26",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "[3] Inspection Check Points",
    key: "3",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.27 Upper Limit Switch",
    key: "3.27",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.1 Earthing",
    key: "3.1",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.28 Lower Limit switch",
    key: "3.28",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.2 Base Enclosure (Must)",
    key: "3.2",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.29 Door limit switch (Entry)",
    key: "3.29",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.3 Landing Gate",
    key: "3.3",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.30 Door Limit Switch (Exit)",
    key: "3.30",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.4 Cage Gate",
    key: "3.4",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.31 Micro Limit switch",
    key: "3.31",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.5 Interlocks of landing Gate",
    key: "3.5",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.32 Load Indicator",
    key: "3.32",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.6 Interlocks of Cage Gate",
    key: "3.6",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.33 Overload Alarm",
    key: "3.33",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.7 Cage/Platform Buffers",
    key: "3.7",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.34 ELCB",
    key: "3.34",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.8 Suspension Rope/Chain & Attachment",
    key: "3.8",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.35 Drop Test with Load and no load",
    key: "3.35",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.9 Safety Gear (Preventing fall of)",
    key: "3.9",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.36 Drop Test Report",
    key: "3.36",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.10 Brake",
    key: "3.10",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.11 Working of Spur Gearing (Gear Box)",
    key: "3.11",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  },
  {
    label: "3.12 Other Electrical Equipment",
    key: "3.12",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok", "NA"],
  }
];

export const CheckList3 = [
  {
    label: "(1) Identification/ Description:",
    key: "(1)",
    value: "",
    type: "Textfield",
  },
  {
    label: "",
    key: "",
    value: "",
  },
  {
    label: "1.1 Name of Manufacturer",
    key: "1.1",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.8 Maximum Capacity",
    key: "1.8",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.2 Sr.No",
    key: "1.2",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.9 Support Level",
    key: "1.9",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.3 Model No:",
    key: "1.3",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.10 Present Height",
    key: "1.10",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.4 Crane type (Self erecting /Lutfing / Stationery)",
    key: "1.4",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.11 Number of Mast Base Other",
    key: "1.11",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.5 Tip Load Capacity",
    key: "1.5",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.12 Mast Size",
    key: "1.12",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.6 Hoist Rope Dia",
    key: "1.6",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.13 Location Tower Name",
    key: "1.13",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.7 Trolley Rope Dia",
    key: "1.7",
    value: "",
    type: "Textfield",
  },
  {
    label: "1.14 Boom Length",
    key: "1.14",
    value: "",
    type: "Textfield",
  },
  {
    label: "(2) Manufacturing Details",
    key: "(2)",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.14 Trolley Rope & Pulley condition",
    key: "3.14",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "2.1 Manufacturing Date/date of 1st at site",
    key: "2.1",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.15 Trolley Braking",
    key: "3.15",
    value: "",
    type: "Checkbox",
    options: ["OK", "Not Ok"],
  },
  {
    label: "(3) Inspection Check Points",
    key: "(3)",
    value: "",
    type: "Textfield",
  },
  {
    label: "3.16 Trolley Limit",
    key: "3.16",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not OK"],
  },
  {
    label: "3.1 Earthing",
    key: "3.1",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not OK"],
  },
  {
    label: "3.17 Slewing Braking",
    key: "3.17",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not OK"],
  },
  {
    label: "3.2 Input Supply Vol/Amps",
    key: "3.2",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not OK"],
  },
  {
    label: "3.18 Slewing limit Switch",
    key: "3.18",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not OK"],
  },
  {
    label: "3.3 Cable Joints and Connections",
    key: "3.3",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not OK"],
  },
  {
    label: "3.19 Lubrication of Slewing gear",
    key: "3.19",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not OK"],
  },
  {
    label: "3.4 Foundation (Enclosure)",
    key: "3.4",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.20 Hoist Rope Condition",
    key: "3.20",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.5 Mast Joint",
    key: "3.5",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.21 Hoist Rope Drum",
    key: "3.21",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.6 Mast Joint Pins",
    key: "3.6",
    value: "",
    type: "Checkbox",
    options: ["OK", "Not Ok"],
  },
  {
    label: "3.22 Hoist Limit Switch.",
    key: "3.22",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not OK"],
  },
  {
    label: "3.7 Wall Ties (Standard/Modified)",
    key: "3.7",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.23 Load Limit Switch",
    key: "3.23",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.8 Total Protection system Functioning",
    key: "3.8",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.24 Lutffing Jib Limit Switch",
    key: "3.24",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.9 Function of master Controller",
    key: "3.9",
    value: "",
    type: "Checkbox",
    options: ["OK", "Not Ok"],
  },
  {
    label: "3.25 Hook Latch",
    key: "3.25",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not OK"],
  },
  {
    label: "3.10 Functioning Anemometer",
    key: "3.10",
    value: "",
    type: "Checkbox",
    options: ["Ol", "Not Ok"],
  },
  {
    label: "3.26 Overall Condition Of Cabin",
    key: "3.26",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.11 Functioning of Aviation Lamps",
    key: "3.11",
    value: "",
    type: "Checkbox",
    options: ["OK", "Not Ok"],
  },
  {
    label: "3.27 Safety Railing on Counter Jib",
    key: "3.27",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.12 Lightning Arrestor Fitted Working",
    key: "3.12",
    value: "",
    type: "Checkbox",
    options: ["Yes", "No"],
  },
  {
    label: "3.28 Anti Collision Devise",
    key: "3.28",
    value: "",
    type: "Checkbox",
    options: ["Ok", "Not Ok"],
  },
  {
    label: "3.13 Over Load Alarm",
    key: "3.13",
    value: "",
    type: "Checkbox",
    options: ["Yes", "No"],
  },
  {
    label: "3.29 Operator Trained & Certified",
    key: "3.29",
    value: "",
    type: "Checkbox",
    options: ["Yes", "No"],
  },
];

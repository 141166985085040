import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Toolbar,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { CheckList1, CheckList2, CheckList3, checkListNames } from "./constant";
import { BASE_URL } from "../../global";
import { toPng } from "html-to-image";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import axios from "axios";
import headerimg from "../image/checklistheader.png";
import footerimg from "../image/checklistfooter.png";
import DownloadPdfLoaderModal from "./pdfLoader";

const cellStyles = {
  labelColumn: {
    padding: "3px",
    fontWeight: "bold",
    textAlign: "left",
    border: "none",
    fontSize: "11px",
  },
  textfield: {
    width: "100%",
    fontSize: "11px",
  },
  labelColumnborder: {
    padding: "3px",
    fontWeight: "bold",
    textAlign: "left",
    width: "25%",
    borderBottom: "none",
    fontSize: "11px",
  },
};

const EditCheckListPrint = () => {
  const { checkListId, checkListtypeId } = useParams();
  const [formData, setFormData] = useState({});
  const printComponentRef = React.useRef();
  const [checkListType, setcheckListType] = useState([]);
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);

  const getChecklist = (event) => {
    let url = BASE_URL;
    axios
      .get(url + `checkList/${checkListId}`)
      .then((res) => {
        console.log(res.data);
        const checklistData = res.data[0];
        const parsedConfiguration = JSON.parse(checklistData.configuration);
        setFormData(parsedConfiguration);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePrint = () => {
    const printContents = document.getElementById("printContents").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  useEffect(() => {
    getChecklist();
  }, [checkListId != 0]);

  useEffect(() => {
    if (checkListtypeId == 1) {
      setcheckListType(CheckList1);
    } else if (checkListtypeId == 2) {
      setcheckListType(CheckList2);
    } else if (checkListtypeId == 3) {
      setcheckListType(CheckList3);
    }
  }, [checkListtypeId]);

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${headerimg} style="width:100%; height:170px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${footerimg} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [50, 1, 40, 1],
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

        // border add for contnet
        // const borderWidth = 0.6;
        // const leftMargin = 15;
        // const borderX = leftMargin;
        // const borderY = 5;
        // const rightMargin = 595 - leftMargin * 0.95;
        // pdf.setLineWidth(borderWidth);
        // pdf.rect(borderX, borderY, rightMargin - leftMargin, 750);

        //   let image = await generateDynamicFooter(
        //     i,
        //     totalPages
        //   );
        //   pdf.addImage(image, 0, 760, 595, 85);

        // if (letterHead) {
        let image = await generateLetterHeadHeader();
        pdf.addImage(image, 0, 0, 595, 50);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 800, 595, 45);
        // }
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `checklisrt.pdf`;
      a.click();

      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  return (
    <>
      <Box
        sx={{ width: "95%", margin: "auto" }}
        ref={printComponentRef}
        id="printContents"
      >
        {/* <div>
          <img src={headerimg} style={{height:"55px", width:"100%", margin:"auto" , objectFit:"contain"}} />
        </div> */}
        <h5 style={{ fontSize: "14px", textAlign: "center" }}>
          <b>
            {checkListtypeId == 1 || checkListtypeId == 2
              ? `INSPECTION AND SAFETY AUDIT CHECKLIST FOR `
              : `INSPECTION CHECKLIST FOR `}

            <u style={{ textTransform: "uppercase" }}>
              {checkListtypeId == 1
                ? checkListNames[0]
                : checkListtypeId == 2
                ? checkListNames[1]
                : checkListNames[2]}
            </u>
          </b>
        </h5>
        <div>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ ...cellStyles.labelColumn, width: "25%" }}>
                <b>Date of Inspection:</b>
              </td>
              <td style={{ ...cellStyles.labelColumn, width: "25%" }}>
                <span style={{ fontWeight: "100" }}>
                  {formData.dateOfInspection || ""}
                </span>
              </td>
              <td style={{ ...cellStyles.labelColumn, width: "25%" }}>
                <b>Inspected By:</b>
              </td>
              <td style={{ ...cellStyles.labelColumn, width: "25%" }}>
                <span style={{ fontWeight: "100" }}>
                  {formData.inspectedBy || ""}
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ ...cellStyles.labelColumn, width: "25%" }}>
                <b>Customer Name:</b>
              </td>
              <td colSpan={3} style={cellStyles.labelColumn}>
                <span style={{ fontWeight: "100" }}>
                  {formData.customerName || ""}
                </span>
              </td>
            </tr>
            <tr>
              <td style={cellStyles.labelColumn}>
                <b>Site Address:</b>
              </td>
              <td colSpan={3} style={cellStyles.labelColumn}>
                <span style={{ fontWeight: "100" }}>
                  {formData.sitaddress || ""}
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <table style={{ width: "100%" }}>
            {checkListType.map(
              (item, index) =>
                index % 2 === 0 && (
                  <tr key={index}>
                    <td style={{...cellStyles.labelColumnborder,borderRight:"none"}}>
                      <b>{item.label}</b>
                    </td>
                    <td style={{...cellStyles.labelColumnborder,borderRight:"none"}}>
                      {item.type === "Textfield" && (
                        <span style={{ fontWeight: "100" }}>
                          {formData[item.key]}
                        </span>
                      )}
                      {item.type === "Checkbox" && (
                        <div>
                          {item.options.map((option, optionIndex) => (
                            <span
                              key={optionIndex}
                              style={{ fontWeight: "100" }}
                            >
                              <Checkbox
                                size="small"
                                sx={{ m: "0px", p: "0px 5px" }}
                                checked={formData[item.key] === option}
                              />
                              {option}
                            </span>
                          ))}
                        </div>
                      )}
                    </td>
                    <td style={{...cellStyles.labelColumnborder,borderRight:"none"}}>
                      <b>{checkListType[index + 1]?.label}</b>
                    </td>
                    <td style={cellStyles.labelColumnborder}>
                      {checkListType[index + 1]?.type === "Textfield" && (
                        <span style={{ fontWeight: "100" }}>
                          {formData[checkListType[index + 1]?.key]}
                        </span>
                      )}
                      {checkListType[index + 1]?.type === "Checkbox" && (
                        <div>
                          {checkListType[index + 1]?.options.map(
                            (option, optionIndex) => (
                              <span
                                key={optionIndex}
                                style={{ fontWeight: "100" }}
                              >
                                <Checkbox
                                  size="small"
                                  sx={{ m: "0px", p: "0px 5px" }}
                                  checked={
                                    formData[checkListType[index + 1]?.key] ===
                                    option
                                  }
                                />
                                {option}
                              </span>
                            )
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                )
            )}
            {checkListtypeId != 3 ? (
              <tr>
                <th
                  colSpan={4}
                  style={{
                    ...cellStyles.labelColumnborder,
                    borderBottom: "1px solid black",
                  }}
                >
                  <b>
                    Any Other Important information not covered in check list:
                  </b>
                  <br />
                  <span style={{ fontWeight: "100" }}>
                    {formData.otherInfo}
                  </span>
                </th>
              </tr>
            ) : (
              <tr>
                <th
                  colSpan={4}
                  style={{ borderTop: "none", borderBottom: "1px solid black" }}
                ></th>
              </tr>
            )}
            {checkListtypeId == 3 && (
              <tr>
                <th colSpan={4} style={cellStyles.labelColumn}>
                  <b>Type of Test:</b>
                  {["Visula", "Load Test"].map((option, optionIndex) => (
                    <span key={optionIndex} style={{ fontWeight: "100" }}>
                      <Checkbox
                        size="small"
                        sx={{ m: "0px", p: "0px 5px" }}
                        checked={formData.typetest === option}
                      />
                      {option}
                    </span>
                  ))}
                </th>
              </tr>
            )}
            <tr>
              <th colSpan={4} style={cellStyles.labelColumn}>
                <b>Result:</b>
                {["Fit for use", "Need rapirs", "Unfit for use"].map(
                  (option, optionIndex) => (
                    <span key={optionIndex} style={{ fontWeight: "100" }}>
                      <Checkbox
                        size="small"
                        sx={{ m: "0px", p: "0px 5px" }}
                        checked={formData.result === option}
                      />
                      {option}
                    </span>
                  )
                )}
              </th>
            </tr>
            <tr>
              <td colSpan={2} style={cellStyles.labelColumn}>
                <b>Customer signature: </b>
                <span>_________</span>
              </td>
              <td colSpan={2} style={cellStyles.labelColumn}>
                <b>Inspector signature: </b>
                <span>_________</span>
              </td>
            </tr>
          </table>
        </div>
        {/* <div>
          <img src={footerimg} style={{height:"55px", width:"100%", margin:"auto" , objectFit:"contain"}} />
        </div> */}
        <div style={{ width: "100%" }} id="header-section"></div>
      </Box>
      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ marginRight: "20px" }}
          onClick={generatePDF}
        >
          Print
        </Button>
      </Toolbar>

      {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Pdf"} />
      )}
    </>
  );
};
export default EditCheckListPrint;
